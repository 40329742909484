import Gallery1 from '@assets/images/g-hana-1.jpg';
import GalleryT1 from '@assets/images/g-hana-t-1.jpg';
import Gallery4 from '@assets/images/g-hana-4.jpg';
import GalleryT4 from '@assets/images/g-hana-t-4.jpg';
import Gallery5 from '@assets/images/g-hana-5.jpg';
import GalleryT5 from '@assets/images/g-hana-t-5.jpg';
import Image5 from '@assets/images/s-hana-5.jpg';
import Image5thumb from '@assets/images/s-hana-t-5.jpg';
import Image6 from '@assets/images/s-hana-6.jpg';
import Image6thumb from '@assets/images/s-hana-t-6.jpg';
import Gallery6 from '@assets/images/g-hana-6.jpg';
import GalleryT6 from '@assets/images/g-hana-t-6.jpg';
import Gallery7 from '@assets/images/g-hana-7.jpg';
import GalleryT7 from '@assets/images/g-hana-t-7.jpg';
import Gallery8 from '@assets/images/g-hana-8.jpg';
import GalleryT8 from '@assets/images/g-hana-t-8.jpg';
import Gallery9 from '@assets/images/g-hana-9.jpg';
import GalleryT9 from '@assets/images/g-hana-t-9.jpg';
import Gallery10 from '@assets/images/g-hana-10.jpg';
import GalleryT10 from '@assets/images/g-hana-t-10.jpg';
import Gallery11 from '@assets/images/g-hana-11.jpg';
import GalleryT11 from '@assets/images/g-hana-t-11.jpg';
import Gallery12 from '@assets/images/g-hana-12.jpg';
import GalleryT12 from '@assets/images/g-hana-t-12.jpg';
import Gallery13 from '@assets/images/g-hana-13.jpg';
import GalleryT13 from '@assets/images/g-hana-t-13.jpg';
import Gallery14 from '@assets/images/g-hana-14.jpg';
import GalleryT14 from '@assets/images/g-hana-t-14.jpg';
import Gallery15 from '@assets/images/g-hana-15.jpg';
import GalleryT15 from '@assets/images/g-hana-t-15.jpg';
import Gallery16 from '@assets/images/g-hana-16.jpg';
import GalleryT16 from '@assets/images/g-hana-t-16.jpg';
import Gallery17 from '@assets/images/g-hana-17.jpg';
import GalleryT17 from '@assets/images/g-hana-t-17.jpg';
import Gallery18 from '@assets/images/g-hana-18.jpg';
import GalleryT18 from '@assets/images/g-hana-t-18.jpg';
import Gallery19 from '@assets/images/g-hana-19.jpg';
import GalleryT19 from '@assets/images/g-hana-t-19.jpg';
import Gallery20 from '@assets/images/g-hana-20.jpg';
import GalleryT20 from '@assets/images/g-hana-t-20.jpg';
import Gallery21 from '@assets/images/g-hana-21.jpg';
import GalleryT21 from '@assets/images/g-hana-t-21.jpg';
import Gallery22 from '@assets/images/g-hana-22.jpg';
import GalleryT22 from '@assets/images/g-hana-t-22.jpg';
import Gallery23 from '@assets/images/g-hana-23.jpg';
import GalleryT23 from '@assets/images/g-hana-t-23.jpg';
import Gallery24 from '@assets/images/g-hana-24.jpg';
import GalleryT24 from '@assets/images/g-hana-t-24.jpg';
import Gallery25 from '@assets/images/g-hana-25.jpg';
import GalleryT25 from '@assets/images/g-hana-t-25.jpg';
import Gallery26 from '@assets/images/g-hana-26.jpg';
import GalleryT26 from '@assets/images/g-hana-t-26.jpg';
import Gallery27 from '@assets/images/g-hana-27.jpg';
import GalleryT27 from '@assets/images/g-hana-t-27.jpg';
import Gallery28 from '@assets/images/g-hana-28.jpg';
import GalleryT28 from '@assets/images/g-hana-t-28.jpg';
import Gallery29 from '@assets/images/g-hana-29.jpg';
import GalleryT29 from '@assets/images/g-hana-t-29.jpg';
import Gallery30 from '@assets/images/g-hana-30.jpg';
import GalleryT30 from '@assets/images/g-hana-t-30.jpg';
import Gallery31 from '@assets/images/g-hana-31.jpg';
import GalleryT31 from '@assets/images/g-hana-t-31.jpg';
import Gallery32 from '@assets/images/g-hana-32.jpg';
import GalleryT32 from '@assets/images/g-hana-t-32.jpg';

export const photos = [
  {
    original: Gallery1,
    thumbnail: GalleryT1,
  },
  {
    original: Image6,
    thumbnail: Image6thumb,
  },
  {
    original: Image5,
    thumbnail: Image5thumb,
  },
  {
    original: Gallery4,
    thumbnail: GalleryT4,
  },
  {
    original: Gallery5,
    thumbnail: GalleryT5,
  },
  {
    original: Gallery6,
    thumbnail: GalleryT6,
  },
  {
    original: Gallery7,
    thumbnail: GalleryT7,
  },
  {
    original: Gallery8,
    thumbnail: GalleryT8,
  },
  {
    original: Gallery9,
    thumbnail: GalleryT9,
  },
  {
    original: Gallery10,
    thumbnail: GalleryT10,
  },
  {
    original: Gallery11,
    thumbnail: GalleryT11,
  },
  {
    original: Gallery12,
    thumbnail: GalleryT12,
  },
  {
    original: Gallery13,
    thumbnail: GalleryT13,
  },
  {
    original: Gallery14,
    thumbnail: GalleryT14,
  },
  {
    original: Gallery15,
    thumbnail: GalleryT15,
  },
  {
    original: Gallery16,
    thumbnail: GalleryT16,
  },
  {
    original: Gallery17,
    thumbnail: GalleryT17,
  },
  {
    original: Gallery18,
    thumbnail: GalleryT18,
  },
  {
    original: Gallery19,
    thumbnail: GalleryT19,
  },
  {
    original: Gallery20,
    thumbnail: GalleryT20,
  },
  {
    original: Gallery21,
    thumbnail: GalleryT21,
  },
  {
    original: Gallery22,
    thumbnail: GalleryT22,
  },
  {
    original: Gallery23,
    thumbnail: GalleryT23,
  },
  {
    original: Gallery24,
    thumbnail: GalleryT24,
  },
  {
    original: Gallery25,
    thumbnail: GalleryT25,
  },
  {
    original: Gallery26,
    thumbnail: GalleryT26,
  },
  {
    original: Gallery27,
    thumbnail: GalleryT27,
  },
  {
    original: Gallery28,
    thumbnail: GalleryT28,
  },
  {
    original: Gallery29,
    thumbnail: GalleryT29,
  },
  {
    original: Gallery30,
    thumbnail: GalleryT30,
  },
  {
    original: Gallery31,
    thumbnail: GalleryT31,
  },
  {
    original: Gallery32,
    thumbnail: GalleryT32,
  },
];
